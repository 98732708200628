import React from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/init.scss'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div className="layout">
        <Helmet defaultTitle="Rafal M Cichon Personal Page">

          <meta name="google-site-verification" content="tD5bQrpGQo1oL2QBoqAg2E5kg5r_zXvHvUm4e-61Ayo" />

        </Helmet>
        {children}
      </div>
    )
  }
}

export default Layout
